export default {
  name: 'Academy Management Software',
  siteURL:
    'https://www.mustangscricketacademy.com/our-programs-mustangs-cricket-academy-texas-usa',
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/mustangs_cricket_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#8ccee9',
    mobileSignInColor: '#8ccee9',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#8ccee9',
    accent1Color: '#191f3d',

    primary: {
      main: '#8ccee9',
    },
    secondary: {
      main: '#191f3d',
    },
  },
  sideNav: {
    selectedColor: '#8ccee9',
  },
  icons: {
    cart: '#8ccee9',
  },
  statusBar: {
    backgroundColor: '#8ccee9',
  },
};
